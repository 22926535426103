import * as React from 'react';
import styled from 'styled-components';
import { ArrowIcon } from '../../ui/icons/Arrow';
import { usePrivacyContext } from './Context';

const StyledMenu = styled.nav`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary};
    user-select: none;

    .arrow {
      margin-left: 5px;
      width: 20px;

      svg {
        width: inherit;
        height: inherit;
        path {
          stroke: ${({ theme }) => theme.colors.primary};
        }
      }

      &.arrow-open {
        transform: rotate(180deg);
      }

      @media all and (min-width: 960px) {
        display: none;
      }
    }

    &:hover {
      cursor: pointer;
    }

    @media all and (min-width: 960px) {
      &:hover {
        cursor: initial;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;

    &.show {
      display: block;
    }

    @media all and (min-width: 960px) {
      display: block !important;
    }

    li {
      display: block;
      border-bottom: 1px solid rgb(50, 50, 50);

      &:last-child {
        border-bottom: none;
      }

      a {
        transition: color 0.15s ease-in-out;
        display: block;
        padding: 0.5rem 0 0.5rem 0;
        color: rgb(160, 160, 160);

        &:hover {
          cursor: pointer;
          color: rgb(255, 255, 255);
        }

        &.active {
          color: rgb(255, 255, 255);
        }
      }
    }
  }
`;

export const Menu: React.FC = () => {
  const [show, setShow] = React.useState(false);
  const { sections, active, setActive } = usePrivacyContext();

  const scrollToSection = (section: string) => {
    setActive(section);
    if (typeof document !== 'undefined') {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView(true);
      }
      const scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - 95);
      }
    }
  };

  return (
    <StyledMenu>
      <div className="top" onClick={() => setShow((prev) => !prev)}>
        Innholdsliste{' '}
        <ArrowIcon className={show ? 'arrow arrow-open' : 'arrow'} />
      </div>
      <ul className={show ? 'show' : ''}>
        {sections.map((item) => (
          <li key={`section_${item.id}`}>
            <a
              className={active === item.id ? 'active' : ''}
              onClick={() => scrollToSection(item.id)}
            >
              {item.menuLabel}
            </a>
          </li>
        ))}
      </ul>
    </StyledMenu>
  );
};
