import * as React from 'react';

interface ISection {
  id: string;
  menuLabel: string;
}

interface IPrivacyContext {
  scrolled: boolean;
  active: string;
  setActive: (value: string) => void;
  sections: ISection[];
  initSection: (args: ISection) => void;
}

export const PrivacyContext = React.createContext<IPrivacyContext>({
  scrolled: false,
  active: '1',
  setActive: () => {},
  sections: [],
  initSection: () => {},
});

export const usePrivacyContext = () => {
  return React.useContext(PrivacyContext);
};

export const PrivacyContextProvider: React.FC = ({ children }) => {
  const [scrolled, setScrolled] = React.useState(false);
  const [active, setActive] = React.useState('1');
  const [sections, setSections] = React.useState<ISection[]>([]);

  const initSection = (args: ISection) => {
    setSections((item) => [...item, args]);
  };

  const handleScroll = () => {
    if (window.scrollY > 100 && !scrolled && window.innerWidth > 960) {
      setScrolled(true);
    } else if (!scrolled && window.scrollY < 100 && window.innerWidth > 960) {
      setScrolled(false);
      /* Reset active if top */
      setActive('1');
    } else {
      setScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <PrivacyContext.Provider
      value={{ scrolled, active, setActive, sections, initSection }}
    >
      {children}
    </PrivacyContext.Provider>
  );
};
