import * as React from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { usePrivacyContext } from './Context';

const StyledContentSection = styled.section`
  display: block;
  width: 100%;
  margin: 1rem 0;
`;

interface ISection {
  id: string;
  menuLabel: string;
}

export const Section: React.FC<ISection> = ({ id, menuLabel, children }) => {
  const { setActive, initSection } = usePrivacyContext();

  React.useEffect(() => {
    initSection({ id, menuLabel });
  }, []);

  return (
    <>
      <Waypoint
        topOffset={130}
        scrollableAncestor="window"
        onLeave={() => setActive(id)}
      />
      <StyledContentSection id={id}>{children}</StyledContentSection>
    </>
  );
};
