import * as React from 'react';
import { darken } from 'polished';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { Seo } from '../../../utils/seo/Index';
import { SiteContext } from '../../../utils/context/SiteContext';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Container } from '../../layout/Container';
import { Button } from '../../ui/button/Button';
import { Menu } from './Menu';
import { Section } from './Section';
import { PrivacyContextProvider, usePrivacyContext } from './Context';
import {
  Table,
  TableColumn,
  TableHeader,
  TableRow
} from '../../ui/table/Index';

const StyledPage = styled(animated.div)`
  padding-top: 66px;
`;

const StyledPrivacyWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-column-gap: 2rem;
  width: 100%;
  padding-top: 3rem;

  @media all and (min-width: 960px) {
    grid-template-columns: 320px minmax(0, 1fr);
  }
`;

const PrivacyContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  padding: 0 30px;
  margin: 0 auto;
`;

const StyledSidebar = styled.aside``;

const StyledInsideSidebar = styled.div<{ sticky: boolean }>`
  position: ${({ sticky }) => (sticky ? 'fixed' : 'relative')};
  top: ${({ sticky }) => (sticky ? '100px' : 'auto')};
  width: 320px;
`;

const StyledContent = styled.main`
  h3 {
    margin-top: 0;
  }
  h4 {
    font-size: 1.5em;
  }
  a {
    word-break: break-all;
  }
`;

const TopHeading = styled.div`
  width: 100%;
  background: ${({ theme }) => darken(0.05, theme.colors.body)};
  padding: 2em 0;

  h2 {
    margin: 0;
  }
`;

const Index: React.FC<RouteChildrenProps> = () => {
  const { scrolled } = usePrivacyContext();

  const { fadeIn } = useFadeTransition({
    immidiate: true
  });

  const openPrivacy = () => {
    if (typeof window.Privacy !== 'undefined') {
      if (typeof window.Privacy.show === 'function') {
        window.Privacy.show();
        window.scroll(0, 0);
      }
    }
  };

  return (
    <>
      <Seo
        title="Personvernpolicy - PrivatMegleren"
        description="Det er viktig for oss at du vet hvilke opplysninger vi innhenter om deg, og at du føler deg trygg på at personvernet ditt er ivaretatt."
      />
      <StyledPage style={fadeIn}>
        <TopHeading>
          <PrivacyContainer>
            <h1>Personvernpolicy</h1>
          </PrivacyContainer>
        </TopHeading>
        <PrivacyContainer>
          <StyledPrivacyWrapper>
            <StyledSidebar>
              <StyledInsideSidebar sticky={scrolled}>
                <Button
                  type="primary"
                  onClick={() => openPrivacy()}
                  colorTheme="gold"
                >
                  Personvernsenter
                </Button>
                <Menu />
              </StyledInsideSidebar>
            </StyledSidebar>
            <StyledContent>
              <Section id="1" menuLabel="Introduksjon">
                <h3>Introduksjon</h3>
                <p>
                  Det er viktig for oss at du vet hvilke opplysninger vi
                  innhenter om deg, og at du føler deg trygg på at personvernet
                  ditt er ivaretatt.
                </p>
                <p>
                  Under følger en forklaring på hvordan og hvorfor vi samler
                  data om brukerne av nettsiden vår, samt til hvilket formål vi
                  benytter denne informasjonen.
                </p>
              </Section>
              <Section id="2" menuLabel="Hvem er behandlingsansvarlig?">
                <h3>Hvem er behandlingsansvarlig?</h3>
                <p>
                  PrivatMegleren, ved administrerende direktør, er ansvarlig for
                  behandlingen av opplysninger som kan knyttes til deg som
                  privatperson.
                </p>
                <p>
                  Nordea Bank er samarbeidspartner for banktjenester og vil være
                  behandlingsansvarlig når en person ber om kontakt med dem.
                  Nordea benytter Proxima Code AS som leverandør av Tipsportalen
                  for å formidle kontakt mellom de involverte selskapene.
                </p>
              </Section>
              <Section id="3" menuLabel="Hva er formålet?">
                <h3>Hva er formålet?</h3>
                <p>
                  Opplysningene som innhentes om deg benyttes til tekniske,
                  analytiske og markedsføringsrelaterte formål.
                </p>
                <ul>
                  <li>
                    For å kunne forbedre nettsidens funksjonalitet,
                    brukeropplevelse og innhold, samler og analyserer vi data om
                    din og andre brukeres nettadferd
                  </li>
                  <li>
                    For å kunne gi deg relevante tilbud og tilpasset innhold i
                    andre kanaler og annonsenettverk, som Facebook, Google og
                    Snapchat, benytter vi informasjonskapsler utstedt av
                    tredjepart.
                  </li>
                  <li>
                    Kunne ta kontakt med deg i forbindelse med salg eller kjøp
                    av eiendom i tråd med lov om eiendomsmegling
                  </li>
                </ul>
              </Section>
              <Section id="4" menuLabel="Hva er det rettslige grunnlaget?">
                <h3>Hva er det rettslige grunnlaget?</h3>
                <p>
                  Meglerbransjen er underlagt lovkrav som følger av lov og
                  forskrift om eiendomsmegling: Lovens formål er å legge til
                  rette for at omsetning av fast eiendom ved bruk av mellommann
                  skjer på en sikker, ordnet og effektiv måte, samt å legge til
                  rette for at partene i handelen mottar uhildet bistand.
                </p>
                <p>
                  Nettsiden tar imot personopplysninger fra forskjellige kanaler
                  og distribuerer de til rett kontor/ansatt i
                  Privatmegler-kjeden via kryptert e-post eller tipsportalen,
                  slik at kunden og ansatt får utført sitt ønske om
                  eiendomssalg, eiendomskjøp, eller salgsfremmende tiltak. Disse
                  kanalene kan være via PrivatMeglerens hjemmeside
                  (privatmegleren.no) via Webmegler (Fagsystemet) via meglere,
                  finansrådgivere eller andre tjenester/tjenesteleverandører.
                  For å få utført disse tjenestene må kunden akseptere at det
                  samles inn et minimum av informasjon for å få utført
                  tjenesten. Senere i prosessen kan det være behov for
                  ytterligere informasjon som megler eller finansrådgivere
                  trenger for å få utført tjenesten. Samtykke blir innhentet i
                  alle tilfeller.
                </p>
              </Section>
              <Section id="5" menuLabel="Hvilke personopplysninger behandles?">
                <h3>Hvilke personopplysninger behandles?</h3>
                <p>
                  Informasjonen som lagres om deg som bruker, omhandler
                  posisjonen din (IP-adresse), hvordan du navigerer på nettsiden
                  og teknisk informasjon om nettleseren din og operativsystemet
                  ditt.
                </p>
                <p>Eksempler på opplysninger som lagres er:</p>
                <ul>
                  <li>Navn/Etternavn</li>
                  <li>Adresse</li>
                  <li>Postnummer</li>
                  <li>Poststed</li>
                  <li>Telefon/mobile</li>
                  <li>Epost</li>
                  <li>Kommentarer</li>
                  <li>Samtykker</li>
                  <li>Formål</li>
                  <li>Kilde for henvendelsen</li>
                  <li>Registreringsdato</li>
                  <li>Eiendommen du er interessert i</li>
                </ul>
              </Section>
              <Section id="6" menuLabel="Hvor hentes opplysningene fra?">
                <h3>Hvor hentes opplysningene fra?</h3>
                <p>
                  Når du besøker nettsiden vår, registrerer vi informasjon om
                  deg i en såkalt cookie eller informasjonskapsel, og i noen
                  tilfeller IP-adressen din. En cookie er en liten tekstfil som
                  lagres i internminnet til nettleseren din. Videre innhenter og
                  lagrer vi opplysninger fra eventuelle bestillings- og
                  kontaktskjemaer du fyller ut.
                </p>
              </Section>
              <Section
                id="7"
                menuLabel="Er det frivillig å gi fra seg opplysningene?"
              >
                <h3>Er det frivillig å gi fra seg opplysningene?</h3>
                <p>
                  Når du besøker nettsiden vår, vil du få mulighet til å
                  godkjenne eller avvise bruken av informasjonskapsler.
                </p>
                <p>
                  Dersom du velger å ikke akseptere bruken av cookies, kan det
                  føre til at nettsiden ikke fungerer optimalt.
                </p>
                <p>
                  Dersom du ønsker å bli kontaktet av en av våre eiendomsmeglere
                  i forbindelse med salg eller kjøp av eiendom, må du i våre
                  skjemaer registrere kontaktopplysninger. Med
                  kontaktopplysninger mener vi:
                </p>
                <ul>
                  <li>Navn/Etternavn</li>
                  <li>Telefon</li>
                  <li>Epost</li>
                  <li>Postnummer</li>
                </ul>
              </Section>
              <Section
                id="8"
                menuLabel="Utleveres opplysningene til en tredjepart?"
              >
                <h3>Utleveres opplysningene til en tredjepart?</h3>
                <p>
                  For å kunne gi deg en bedre brukeropplevelse på nettsiden vår,
                  samt relevant markedsføring i andre kanaler, som Facebook og
                  Google, benytter vi informasjonskapsler utstedt av tredjepart.
                </p>
                <p>
                  Eksempler på slike tredjepartsaktører er Google, Facebook,
                  Adform, Delta Projects, YouTube, Snapchat og DoubleClick.
                </p>
                <p>
                  PrivatMegleren er Nordeas eiendomsmeglerkjede. I kraft av
                  denne tilknytningen utleverer vi følgende nøytrale
                  opplysninger til Nordea i forbindelse med kundeopplysning og
                  markedsføring: navn, kontaktopplysninger og hvilke
                  tjenester/produkter du har inngått avtale om.
                </p>
                <p>
                  Under følger mer utfyllende informasjon om de aktuelle
                  tredjepartsaktørene.
                </p>

                <h4>
                  Google / Google Analytics / Google (Dynamic) Remarketing /
                  Google Adwords Converter / DoubleClick Floodlight / YouTube /
                  Hotjar
                </h4>
                <p>
                  Et Google-script blir brukt for å levere relevante og
                  tilpassede markedsføringskampanjer i Googles annonsenettverk.
                </p>
                <p>
                  Vi innhenter data om informasjonskapselen i nettleseren din,
                  hvilke typer nettsteder du besøker, hvilke mobilapper du har
                  på enheten din, innstillingene du har valgt i
                  annonseinnstillinger, samt om du har besøkt domenet vårt
                  tidligere.
                </p>
                <p>
                  Google registrerer også nettsteder og apper du har besøkt som
                  tilhører bedrifter som annonserer med Google, tidligere
                  interaksjon med annonser eller annonseringstjenester fra
                  Google, samt Google-profilen din (inkludert aktiviteten din på
                  YouTube og Google+)
                </p>
                <p>
                  Et Hotjar-script blir brukt for å analysere "varmekart" av
                  brukersesjoner, dvs. bevegelse, museklikk og scrolling rundt
                  på enkeltsider. Det brukes også til å kunne gjenskape disse
                  brukersesjonene i ettertid.
                </p>
                <p>
                  Vi gjør ikke følgende: Kobler navn eller personlig
                  identifiserbar informasjon til informasjonskapselen din uten
                  samtykke fra deg. Vi knytter heller ikke informasjonskapselen
                  din til sensitive emner som etnisitet, religion, seksuell
                  legning eller helse uten samtykke fra deg.
                </p>
                <ul>
                  <li>
                    <b>
                      Les mer om dine muligheter for å hindre at Google
                      registrerer atferdsmønsteret ditt:
                    </b>
                    <br />
                    <a href="https://support.google.com/adsense/troubleshooter/1631343">
                      https://support.google.com/adsense/troubleshooter/1631343
                    </a>
                  </li>
                  <li>
                    <b>
                      Les mer om hvordan du kan melde deg av Googles bruk av
                      cookies:
                    </b>
                    <br />
                    <a href="https://adssettings.google.com/u/0/authenticated">
                      https://adssettings.google.com/u/0/authenticated
                    </a>
                  </li>
                  <li>
                    <b>
                      Les mer om hvordan du kan melde deg av Googles DoubleClick
                      sin bruk av cookies:
                    </b>
                    <br />
                    <a href="https://adssettings.google.com/authenticated?hl=en#display_optout">
                      https://adssettings.google.com/authenticated?hl=en#display_optout
                    </a>
                  </li>
                </ul>
                <h4>Google Tag Manager</h4>
                <p>
                  Benyttes til å organisere og styre script som kjører på
                  vårt/våre domene(r).
                </p>
                <h4>Facebook Custom Audience</h4>
                <p>
                  Vi bruker informasjonskapsler og lignende teknologi for å vise
                  annonser på og utenfor Facebooks tjenester. Finn ut mer om
                  hvordan Facebook bestemmer hvilke annonser du får se her:{' '}
                  <a href="https://www.facebook.com/help/562973647153813">
                    https://www.facebook.com/help/562973647153813
                  </a>
                </p>
                <p>
                  Hvis du ønsker å kontrollere og administrere hva slags
                  annonser du ser på Facebook, kan du justere
                  annonseinnstillingene dine. Les om Facebook sine
                  retningslinjer for personvern, samt mulighetene for å hindre
                  at Facebook registrerer ditt adferdsmønster her:{' '}
                  <a href="https://www.facebook.com/about/ads/">
                    https://www.facebook.com/about/ads/
                  </a>
                  .
                </p>
                <h4>Google Tag Manager</h4>
                <p>
                  Benyttes til å organisere og styre script som kjører på
                  vårt/våre domene(r).
                </p>
                <h4>Adform og Delta Projects</h4>
                <p>
                  Adform blir brukt for å gjennomføre markedsføringskampanjer.
                  Vi henter data til brukerstatistikk, eksempelvis antall
                  visninger og klikk på annonser. I tillegg bruker vi data for å
                  skreddersy annonser mot spesifikke målgrupper.
                </p>
                <p>
                  Adform og Delta Projects samler ikke personlig identifiserbar
                  data fra ditt besøk til sine domener, annet enn informasjon
                  som du frivillig legger igjen.
                </p>
                <p>
                  Les mer om Adforms/Deltas retningslinjer for personvern og
                  muligheten for å hindre disse fra å sette cookies og
                  registrere ditt adferdsmønster under.
                </p>
                <p>
                  Adform:{' '}
                  <a href="http://site.adform.com/privacy-policy/en/">
                    http://site.adform.com/privacy-policy/en/
                  </a>
                  <br />
                  Delta Projects:{' '}
                  <a href="http://www.deltaprojects.com/privacy-policy/">
                    http://www.deltaprojects.com/privacy-policy/
                  </a>
                </p>
                <h4>Hotjar</h4>
                <p>
                  Hotjar analyserer og lagrer anonyme brukermønstre via
                  varmekart og opptak av brukersesjoner. Dette gjøres ved at det
                  åpnes en "websocket"-kobling mellom din nettleser og Hotjar
                  sine servere som så leser av hvordan du navigerer deg rundt på
                  siden, samt dine musebevegelser og klikk i sanntid. Disse
                  brukersesjonene kan så vises i et "varmekart" som viser oss de
                  mest brukte elementene på nettsiden, eller spilles av på nytt
                  slik at vi kan se om det er enkelt for deg som bruker å
                  navigere rundt på siden.
                  <br />
                  <br />
                  Hotjar personvernpolicy:{' '}
                  <a href="https://www.hotjar.com/privacy">
                    https://www.hotjar.com/privacy
                  </a>
                </p>
                <h4>Voice App for PrivatMegleren</h4>
                <p>
                  Tjenesten fungerer slik at brukeren gir kommandoer og input
                  via sin stemme. Hardwaren tar imot stemmen og innebygget
                  software konverterer denne stemmen til maskinlesbar tekst på
                  lokalt språk. Denne teksten fremstår som all annen
                  datainnhentet tekst, og sendes inn til PrivatMeglerens
                  mottakssystemer (tipsportalen) som et vanlig tips. Et tips i
                  denne tjenesten vil inneholde brukerens mobilnummer,
                  postnummer og antall kvadratmeter i leiligheten som skal
                  takseres. Dataene blir ikke lagret hverken i hardwaren eller
                  dens software, eller på eksterne servere. Henvendelsen sendes
                  direkte til PrivatMeglerens mottakssystem og lagres og
                  databehandles der. Brukeren samtykker til å bli kontaktet ved
                  å selv aktivt å takke ja til dette, og deretter legge igjen
                  sitt telefonnummer. Du kan når som helst få dine data slettet
                  ved å logge deg inn på vårt{' '}
                  <a onClick={() => openPrivacy()}>Senter for personvern</a>.
                  <br />
                  <br />
                </p>
                <h4>Nybygg Notis</h4>
                <p>
                  En tjeneste og et medlemskap som finner planlagte prosjekter i
                  nærheten av deg, basert på din nåværende eiendomsverdi.
                </p>
                <p>
                  Formålet er å holde interessenter oppdatert med eiendomsverdi
                  og prosjekter som kun er tilgjengelig dersom du er medlem av
                  Notis. Videre får medlemmer forkjøpsrett på Notis-prosjekter.
                  Dette gjøres ved å fylle ut et skjema "Bruk forkjøpsrett", som
                  sender medlemsnummer og kø-nummer til Webmegler. Webmegler er
                  fagsystemet til PrivatMegleren som leveres av Visma Real
                  Estate AS.{' '}
                </p>
                <p>
                  Prosjekter som ikke er en del av Notis blir også presentert.
                  Innlogging gjøres via Google, Facebook eller eget brukernavn
                  og passord. Opplysninger som lagres er Navn, telefon, e-post,
                  og adresse i henhold til rutiner for Nyhetsbrev (Visma Real
                  Estate AS) og Bestilling av salgsoppgave (Visma Real Estate
                  AS) i tabellene under.
                </p>
                <p>
                  Du kan når som helst få dine data slettet ved å logge deg inn
                  på vårt Senter for personvern.
                </p>
                <h4>Tredjeparts spørringer/third party requests</h4>
                <p>
                  Google Analytics arbeider gjennom tredjeparts spørringer, som
                  lagres av en bruker til en ekstern tjenesteleverandør. Til
                  tross for at disse spørringene ikke setter cookies, kan de
                  fortsatt overføre informasjon til en tredjepart. Ved hjelp av
                  informasjonskapsler utstedt av tredjepart, kan du oppleve å
                  motta markedsføring basert på undersider du har besøkt på
                  nettstedet vårt. Disse informasjonskapslene inneholder kun
                  aggregert informasjon, og ingen opplysninger som kan
                  identifisere deg som bruker.
                </p>
                <p>
                  For enkel oppsummering og valg, gå til vårt{' '}
                  <a href="#" onClick={() => openPrivacy()}>
                    Senter for personvern
                  </a>
                </p>
                <h4>
                  Informasjonsklausul i avtale mellom eier av eiendom og megler:
                </h4>
                <p>
                  Opplysninger knyttet til eiendommen (som adresse, gnr., bnr,
                  eventuelt snr./fnr., eieform, eiendomstype,
                  organisasjonsnummer, andelsnummer, antall rom, soverom,
                  primærrom, bruksareal, etasje, byggeår, tomt, kommunenummer,
                  takst, prisantydning, bilder av eiendom) vil bli utlevert til
                  Oppdragstakers samarbeidspartner Eiendomsverdi AS.
                </p>
                <p>
                  Opplysningene vil bli brukt av Eiendomsverdi AS og
                  Eiendomsverdi AS’ kunder for statistiske og
                  informasjonsmessige formål.
                </p>
                <p>
                  Opplysningene vil bli tilgjengeliggjort for Eiendomsverdi AS
                  sine kvalifiserte kunder gjennom Eiendomsverdi AS sine
                  tjenester.
                </p>
                <p>
                  All behandling av eventuelle personopplysninger i tilknytning
                  til opplysningene og bildene vil skje i samsvar med til enhver
                  tid lovmessig krav til sikkerhet, konfidensialitet mv.
                </p>
                <p>
                  Opplysningene knyttet til eiendommene vil kun bli lagret og
                  brukt så lenge det er nødvendig etter formålet.
                </p>
                <p>
                  Eiendomsverdi AS, PB 1052 Sentrum, 0104 Oslo, tlf. 23 00 28 20
                  er ansvarlig for bruken av opplysningene om eiendommene i sine
                  systemer.
                </p>
                <p>
                  Oppdragsgiver har rett til å kreve innsyn i og endring ved
                  eventuell feil i Oppdragsgivers eventuelle personopplysninger
                  som inngår i opplysningene. Oppdragsgiver har også rett til å
                  protestere mot behandlingen og har dessuten rett til å klage
                  på behandlingen til Datatilsynet. Oppdragsgiver kan også har
                  rett til sletting og begrensning av personopplysninger.
                </p>
                <p>
                  Grunnlaget for utlevering av slike opplysninger til
                  Eiendomsverdi AS, er personopplysningsloven § 8f. Ved
                  vurderingen av det rettslige grunnlaget, er det lagt vekt på
                  at allmennyttige hensyn (som statistikk, verdivurdering ved
                  finansiering mv.), overstiger eventuelle personvernulemper,
                  samtidig som opplysningene stort sett uansett er offentlig
                  tilgjengelig og ikke omfattende.
                </p>
                <h4>Klausul i avtale med fotograf</h4>
                <p>
                  Fotografen samtykker til at bildene tatt av eiendommene i
                  forbindelse med oppdrag for Oppdragsgiver kan utleveres og
                  brukes av Oppdragsgivers samarbeidspartner Eiendomsverdi AS og
                  Eiendomsverdi AS’ kunder i Eiendomsverdi AS’ systemer for
                  statistiske og informasjonsmessige formål.
                </p>
                <p>
                  Bildene vil ikke bli brukt på annen måte, og vil heller ikke
                  bli utlevert til eller brukt andre parter enn hva som følger
                  av oppdragsavtalen forøvrig. Bildene vil ikke bli endret
                  utover det som er absolutt nødvendig, og gjengivelsen av
                  bildene i Eiendomsverdi AS’ systemer skal være lojal og ikke
                  krenkende.
                </p>

                <h4>Marketing automation HubSpot</h4>
                <p>
                  Når du fyllet ut kontaktskjemaer på våre nettsider og
                  samtykker til å få tilsendt markedsføring på e-post, lagres
                  dine opplysninger i marketing automation-systemet HubSpot.
                  Systemet benyttes for å automatisk sende ut relevant
                  informasjon til kunder, basert på de personopplysningene som
                  fylles inn.
                </p>

                <h4>Jotform</h4>
                <p>
                  Når du fyllet ut kontaktskjemaer på våre nettsider i
                  forbindelse med "Veien til Solgt" og samtykker til vilkårene,
                  samt at PrivatMegleren kan kontakte deg selv om du ikke blir
                  valgt ut, lagres informasjon hos Jotform. Systemet benyttes
                  til å samle informasjon og samtykker med formål om å hjelpe
                  deg med å selge eiendommen din.
                </p>
              </Section>
              <Section
                id="9"
                menuLabel="Hvordan slettes og arkiveres opplysningene?"
              >
                <h3>Hvordan slettes og arkiveres opplysningene?</h3>
                <h4>
                  Skjemaer fra nettsidene, visningspåmelding og bannere på
                  finn.no (Visma Real Estate AS)
                </h4>
                <Table
                  name="reeltime"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn/etternavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Adresse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon/mobil</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Epost</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Eiendommen</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Formål</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kilde for henvendelse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Brukes til statistikk</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Brukes til statistikk</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kommentarer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>7 dager</TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                </Table>

                <h4>Henvendelser i forbindelse med "Veien til Solgt"</h4>

                <Table
                  name="reeltime"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Fulltnavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>E-post</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Adresse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Type eiendom</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Om hustanden</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Årsak til at du ikke får solgt</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Bilder</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd</TableColumn>
                    <TableColumn>X</TableColumn>
                  </TableRow>
                  <TableRow></TableRow>
                </Table>

                <h4>
                  Henvendelser i forbindelse med salgssum (Visma Real Estate AS)
                </h4>
                <Table
                  name="reeltime2"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn/etternavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd.</TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon/mobil</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd.</TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Eiendommen</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd.</TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd.</TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Formål</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Etter maks 12 mnd.</TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kilde for henvendelse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Brukes til statistikk</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Brukes til statistikk</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kommentarer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>7 dager</TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                </Table>
                <h4>Nyhetsbrev (Visma Real Estate AS)</h4>
                <Table
                  name="reeltime3"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn</TableColumn>
                    <TableColumn>Avhengig av type henvendelse</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Lever inntil avmelding eller ved sletteanmodning
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon</TableColumn>
                    <TableColumn>Avhengig av type henvendelse</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Lever inntil avmelding eller ved sletteanmodning
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>E-post</TableColumn>
                    <TableColumn>Avhengig av type henvendelse</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Lever inntil avmelding eller ved sletteanmodning
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Beholdes til statistikk</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Melding</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Lever inntil avmelding eller ved sletteanmodning
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Beholdes til statistikk</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Lever inntil avmelding eller ved sletteanmodning
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                </Table>
                <h4>Visningsliste og befaringsverktøy Visma Real Estate AS</h4>
                <Table
                  name="codegarden"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn/etternavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei (Passordbes kyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Adresse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Poststed</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon / mobil</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Fødselsnummer/dato</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>E-post</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Eiendommen</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Formål</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kilde for henvendelse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kommentarer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Digitale meglerverktøy (ved befaring, overtagelse,
                      strømbestilling og registrering av interessenter på
                      visning).
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel
                    </TableColumn>
                  </TableRow>
                </Table>
                <h4>Mitt Eiendomskjøp (Knowit AS)</h4>
                <Table
                  name="reeltime3"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn/etternavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Adresse</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuelt lagt inn på "min side"</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer til brukers eiendom.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuelt lagt inn på "min side"</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Poststed</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuelt lagt inn på "min side"</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>
                      Postnummer der bruker ønsker å lete etter eiendom
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Ved forespørsel om kontakt med megler.
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon/mobil</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Ved forespørsel om kontakt (megler, lånetilbud og
                      befaring)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Epost</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved registrering og ved forespørsel om kontakt (megler,
                      lånetilbud og befaring)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Budsjett</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Vi lagrer budsjetts info til brukeren. Følgende data blir
                      lagret: (Ønsket lånebeløp, egenkapital og ønsket
                      betalingsperiode). Lagt til via «Min side»
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Eiendomsdata</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Lagrer Eiendomstype, størrelse, estimert verdi og kjøpesum
                      på brukerens eiendom om de legger dette inn via “min side”
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                </Table>
                <h4>Bestilling av salgsoppgave (Visma Real Estate AS)</h4>
                <Table
                  name="websystemer"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn/etternavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved registering / i oppdragssammenheng
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Etter arkivplikt(ved sletteanmodning)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Adresse</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Poststed</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon/mobil</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved registrering / i oppdragssammenheng
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Etter arkivplikt(ved sletteanmodning)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Epost</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved registrering / i oppdragssammenheng
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Etter arkivplikt(ved sletteanmodning)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Eiendommen</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registrering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn></TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved registrering / i oppdragssammenheng
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Ved registrering / i oppdragssammenheng
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Formål</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kilde for henvendelse</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kommentarer</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                </Table>
                <h4>Søkeagent (Visma Real Estate AS)</h4>
                <Table
                  name="websystemer2"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn/etternavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Etter arkivplikt(ved sletteanmodning)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Adresse</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Poststed</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon/mobil</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Etter arkivplikt(ved sletteanmodning)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Epost</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Eiendommen</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Formål</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kilde for henvendelse</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kommentarer</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Aldri</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                </Table>
                <h4>Nyhetsbrev (hbspr.no)</h4>
                <Table
                  name="hbspr"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Epost</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Umiddelbart etter utsendelse</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                </Table>
                <h4>Håndtering av ønsker om tilbud (leads) Proxima Code AS</h4>
                <Table
                  name="websystemer"
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn/etternavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Adresse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Poststed</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon/mobil</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Fødselsnummer/dato </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>E-post</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Eiendommen</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Formål</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kilde for henvendelse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kommentarer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved ønske om tilbud på e-takst eller salg av eiendom fra
                      Privatmegleren, tilbud på lån, sparing eller andre
                      banktjenester fra Nordea, tilbud på forsikring fra
                      Gjensidige.
                    </TableColumn>
                    <TableColumn>Nei (Passordbeskyttet)</TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                    <TableColumn>
                      Manuell sletting/ anonymisering ved forespørsel.
                      Anonymiseres automatisk etter 90 dager ved inaktivitet fra
                      mottaker.
                    </TableColumn>
                  </TableRow>
                </Table>

                <h4>Marketing automation HubSpot</h4>
                <Table
                  name=""
                  headerColumns={[
                    { label: 'Element' },
                    { label: 'Obligatorisk' },
                    { label: 'Hvor i bruk' },
                    { label: 'Kryptert' },
                    { label: 'Sletterutine' },
                    { label: 'Anonymisering' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Navn/etternavn</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Adresse</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Postnummer</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Telefon/mobil</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Epost</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Eiendommen</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Samtykker</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Formål</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Ja</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Registreringsdato</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>Ved registering</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Manuell sletting/anonymisering ved forespørsel
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow>
                  {/*                   <TableRow>
                    <TableColumn>
                      Postnummer der bruker ønsker å lete etter eiendom
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Ved forespørsel om kontakt med megler.
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow> */}
                  {/*                   <TableRow>
                    <TableColumn>Telefon/mobil</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Ved forespørsel om kontakt (megler, lånetilbud og
                      befaring)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow> */}
                  {/*                   <TableRow>
                    <TableColumn>Epost</TableColumn>
                    <TableColumn>Ja</TableColumn>
                    <TableColumn>
                      Ved registrering og ved forespørsel om kontakt (megler,
                      lånetilbud og befaring)
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow> */}
                  {/*                   <TableRow>
                    <TableColumn>Budsjett</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Vi lagrer budsjetts info til brukeren. Følgende data blir
                      lagret: (Ønsket lånebeløp, egenkapital og ønsket
                      betalingsperiode). Lagt til via «Min side»
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow> */}
                  {/*                   <TableRow>
                    <TableColumn>Eiendomsdata</TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>
                      Lagrer Eiendomstype, størrelse, estimert verdi og kjøpesum
                      på brukerens eiendom om de legger dette inn via “min side”
                    </TableColumn>
                    <TableColumn>Nei</TableColumn>
                    <TableColumn>Manuell sletting ved forespørsel.</TableColumn>
                    <TableColumn>Nei</TableColumn>
                  </TableRow> */}
                </Table>
              </Section>
              <Section
                id="10"
                menuLabel="Hvilke rettigheter har den registrerte og hvilket lands lovverk gjelder?"
              >
                <h3>
                  Hvilke rettigheter har den registrerte og hvilket lands
                  lovverk gjelder?
                </h3>
                <p>
                  Den registrerte har krav på innsyn, retting og sletting av
                  mangelfulle eller uriktige personopplysninger, såfremt dette
                  ikke kommer i konflikt med norsk lov om eiendomsmegling.
                </p>
                <p>
                  Personopplysningsloven stiller krav til internkontroll i form
                  av etablering og vedlikehold av planlagte og systematiske
                  tiltak for å oppfylle kravene i eller i medhold av
                  personopplysningsloven, herunder sikre personopplysningenes
                  kvalitet. Dette kan oppsummeres som:
                </p>
                <ul>
                  <li>
                    rutiner for oppfyllelse av virksomhetens plikter og de
                    registrertes rettigheter
                  </li>
                  <li>rutiner og tekniske tiltak for informasjonssikkerhet</li>
                </ul>
              </Section>
              <Section id="11" menuLabel="Hvordan sikres opplysningen?">
                <h3>Hvordan sikres opplysningen?</h3>
                <p>
                  All kommunikasjon er basert på SSL. Secure Sockets Layer (SSL)
                  er kryptografiske protokoller som tilbyr sikker kommunikasjon
                  på Internett for nettlesing, e-post, lynmeldinger og andre
                  dataoverføringer. Opplysningene blir lagret på sikre servere
                  bak en streng fysisk brannmur som ivaretar våre krav til
                  sikkerhet.
                </p>
              </Section>
              <Section id="12" menuLabel="Kontaktinformasjon">
                <h3>Kontaktinformasjon</h3>
                <p>
                  Dersom du ønsker innsyn, retting eller sletting av dine
                  personopplysninger gå inn på{' '}
                  <a onClick={() => openPrivacy()}>Senter for personvern</a>{' '}
                  eller ta kontakt med oss via e-post{' '}
                  <a href="mailto:post@privatmegleren.no">
                    post@privatmegleren.no
                  </a>{' '}
                  dersom du har spørsmål eller tilbakemeldinger knyttet til ditt
                  personvern.
                </p>
                <h4>
                  Ved et brudd eller mulig brudd på personvernet skal følgende
                  varslinger utføres
                </h4>
                <Table
                  name="brudd"
                  headerColumns={[
                    { label: 'Rolle' },
                    { label: 'Gjør hva' },
                    { label: 'Frist' }
                  ]}
                >
                  <TableRow>
                    <TableColumn>Den som avdekker avvik</TableColumn>
                    <TableColumn>
                      Melder til Daglig leder og ansvarlig hos meglerkjeden
                    </TableColumn>
                    <TableColumn>Umiddelbart</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Daglig leder</TableColumn>
                    <TableColumn>Melder til Kundens ansvarlige</TableColumn>
                    <TableColumn>Umiddelbart</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kundens ansvarlige / Daglig leder</TableColumn>
                    <TableColumn>
                      Melder til bruker om bruddet og hvilket tiltakt som settes
                      i gang
                    </TableColumn>
                    <TableColumn>Umiddelbart</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Kundens ansvarlige / Daglig leder</TableColumn>
                    <TableColumn>Melder brudd til Datatilsynet</TableColumn>
                    <TableColumn>Innen 72 timer</TableColumn>
                  </TableRow>
                </Table>
              </Section>
              <Section id="13" menuLabel="Om cookies">
                <h3>Om cookies</h3>
                <p>
                  Dette er en standard teknologi de fleste nettsteder benytter i
                  dag. En cookie er en liten tekstfil som legges i din
                  nettlesers internminne. Ved å sette en cookie kan vi lagre
                  informasjon om hvordan du navigerer på nettsiden, og gi oss
                  nyttig statistikk som vi bruker til å gjøre nettsidene våre
                  bedre. Nettsiden benytter også informasjonskapsler for å skape
                  og levere relevante og tilpassede markedsføringskampanjer i
                  diverse annonsenettverk som f.eks. Google og Facebook. Du kan
                  se en oversikt over hvilke cookies vi bruker.
                </p>
                <p>
                  De fleste nettlesere (som Google Chrome, Firefox, Internet
                  Explorer, Safari og Opera) er innstilt på å akseptere cookies
                  automatisk, men du kan selv velge å endre innstillingene slik
                  at cookies ikke blir akseptert. Hvis du velger å ikke godta
                  bruk av cookies, kan det føre til at nettsider ikke vil
                  fungere optimalt.
                </p>
                <p>
                  Informasjonen vi samler inn, kan ikke identifisere deg som
                  bruker, og vil heller ikke bli delt med tredjepart.
                </p>
                <h4>Cookies satt av privatmegleren.no</h4>
                <Table
                  name="cookies"
                  headerColumns={[{ label: 'Navn' }, { label: 'Formål' }]}
                >
                  <TableRow>
                    <TableColumn>Google Analytics</TableColumn>
                    <TableColumn>Statistikk, markedsføring</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Google Tag Manager</TableColumn>
                    <TableColumn>Statistikk, markedsføring</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Facebook</TableColumn>
                    <TableColumn>Statistikk, markedsføring</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Optimizely</TableColumn>
                    <TableColumn>Statistikk, A/B-testing</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Hotjar</TableColumn>
                    <TableColumn>Statistikk, A/B-testing</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Digger</TableColumn>
                    <TableColumn>Statistikk, A/B-testing</TableColumn>
                  </TableRow>
                  <TableRow>
                    <TableColumn>Snapchat</TableColumn>
                    <TableColumn>Markedsføring</TableColumn>
                  </TableRow>
                </Table>
              </Section>
            </StyledContent>
          </StyledPrivacyWrapper>
        </PrivacyContainer>
      </StyledPage>
    </>
  );
};

interface IPrivacy extends RouteComponentProps {}

const Privacy: React.FC<IPrivacy> = (props) => {
  return (
    <PrivacyContextProvider>
      <Index {...props} />
    </PrivacyContextProvider>
  );
};

export default Privacy;
