import * as React from 'react';
import styled, { css } from 'styled-components';

const StyledOverflowWrapper = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  margin-bottom: 20px;
  width: 100%;
  min-width: 950px;
  border: none;
  border-collapse: collapse;
  color: #fff;
`;

interface IHeaderColumn {
  label: string;
}

interface ITable extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  headerColumns: IHeaderColumn[];
}

export const Table: React.FC<ITable> = (props) => {
  const tableProps = { ...props };
  delete tableProps.children;

  return (
    <StyledOverflowWrapper>
      <StyledTable {...tableProps}>
        <TableHeader>
          {props.headerColumns.map((item, index) => (
            <TableHeadColumn key={`${props.name}_${index}`}>
              {item.label}
            </TableHeadColumn>
          ))}
        </TableHeader>
        <TableBody>{props.children}</TableBody>
      </StyledTable>
    </StyledOverflowWrapper>
  );
};

export const TableHeader = styled.thead``;

const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  &:last-child {
    border-bottom: solid 1px rgb(50, 50, 50);
  }
`;

const BaseColumn = css`
  text-align: left;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  padding: 1em;
  border-top: solid 1px rgb(50, 50, 50);
  border-left: solid 1px rgb(50, 50, 50);
  &:last-child {
    border-right: solid 1px rgb(50, 50, 50);
  }
`;

export const TableHeadColumn = styled.th`
  ${BaseColumn}
  font-size: 14px;
`;

export const TableColumn = styled.td`
  ${BaseColumn}
  font-size: 16px;
`;
